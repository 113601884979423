<script setup lang="ts">
import { CmsSlot } from '@shopware-pwa/types';
import { getCmsLayoutConfiguration } from '@shopware-pwa/helpers-next';
import { resolveCmsComponent } from '@shopware-pwa/composables-next';

const props = defineProps<{
  content: CmsSlot;
}>();

const DynamicRender = () => {
  const { resolvedComponent, componentName, isResolved } = resolveCmsComponent(
    props.content
  );
  if (resolvedComponent) {
    if (!isResolved)
      return h('div', {}, 'Problem resolving component: ' + componentName);

    const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(
      props.content
    );
    return h(resolvedComponent, {
      content: props.content,
      style: layoutStyles,
      class: cssClasses,
    });
  }
  return h('div', {}, 'Loading...element' + componentName);
};
</script>

<template>
  <div style="margin: 0 auto; max-width: 1240px">
    <DynamicRender />
  </div>
</template>
